<template>
  <div class="zeroblur__app mt-4">
    <div class="text-center d-flex flex-column align-center">
      <img
        width="100"
        height="100"
        :src="require('@/assets/images/svg/zeroblur-icon.svg')"
      >
      <h1 class="app__title">
        ZeroBlur
      </h1>
      <div class="app__headline mt-2 mb-2">
        Never again expose sensitive data while sharing your screen
      </div>
      <p>
        100% free browser extension to blur out your sensitive data
      </p>
    </div>

    <div class="container d-flex flex-wrap align-items-center justify-center">
      <a
        class="me-md-3 mb-xl-0 mb-3"
        href="https://chrome.google.com/webstore/detail/zeroblur/ckmpibbifmcamfmfelkencbbiilpcfjg"
        title="Download ZeroBlur for Chrome"
        target="_blank"
        rel="noopener"
      >
        <img
          src="https://divbyzero.com/wp-content/uploads/2021/12/zeroblurforchrome.svg"
          alt="Download ZeroBlur for Chrome"
          data-lazy-src="https://divbyzero.com/wp-content/uploads/2021/12/zeroblurforchrome.svg"
          data-ll-status="loaded"
          class="entered lazyloaded"
        ><noscript><img src="https://divbyzero.com/wp-content/uploads/2021/12/zeroblurforchrome.svg" alt="Download ZeroBlur for Chrome"></noscript>
      </a>
      <a
        class="me-md-3 mb-xl-0 mb-3"
        href="https://microsoftedge.microsoft.com/addons/detail/zeroblur/fkmncblfmgacfhaofhpofcdeiclhikic"
        title="Download ZeroBlur for Edge"
        target="_blank"
        rel="noopener"
      >
        <img
          src="https://divbyzero.com/wp-content/uploads/2021/12/zeroblurforedge.svg"
          alt="Download ZeroBlur for Edge"
          data-lazy-src="https://divbyzero.com/wp-content/uploads/2021/12/zeroblurforedge.svg"
          data-ll-status="loaded"
          class="entered lazyloaded"
        ><noscript><img src="https://divbyzero.com/wp-content/uploads/2021/12/zeroblurforedge.svg" alt="Download ZeroBlur for Edge"></noscript>
      </a>
      <a
        href="https://addons.mozilla.org/en-US/firefox/addon/zeroblur/"
        title="Download ZeroBlur for Firefox"
        target="_blank"
        rel="noopener"
      >
        <img
          src="https://divbyzero.com/wp-content/uploads/2021/12/zeroblurforFirefox.svg"
          alt="Download ZeroBlur for Firefox"
          data-lazy-src="https://divbyzero.com/wp-content/uploads/2021/12/zeroblurforFirefox.svg"
          data-ll-status="loaded"
          class="entered lazyloaded"
        ><noscript><img src="https://divbyzero.com/wp-content/uploads/2021/12/zeroblurforFirefox.svg" alt="Download ZeroBlur for Firefox"></noscript>
      </a>
    </div>

    <div class="container">
      <v-img src="https://divbyzero.com/wp-content/uploads/2021/12/Zero-Blur-Free-Extension-1024x686.png.webp" />
    </div>

    <div class="container text-center">
      <div class="mt-5 pb-5 text-secondary">
        <h2>Protect your sensitive information</h2>
        <p class="landing-block__text mb-4">
          Blur any sensitive information from any website or web app with this 100% free blur chrome extension. Keep your data safe when delivering demos or recording videos!
        </p>
        <a href="#how_it_works">
          <v-btn
            outlined
            color="primary"
            class="text-decoration-none"
          >
            How does it work
          </v-btn>
        </a>
      </div>
    </div>

    <section class="landing-block landing-block-section-heading text-center col-lg-6 offset-lg-3 mt-5 mb-5">
      <div class="container">
        <span class="landing-block__overtitle text-secondary">Speed Up Your Workflow</span>
        <h2 class="text-primary">
          Speed Up Your Workflow
        </h2>
        <p class="landing-block__text mb-4">
          Have you ever recorded a product demo just to realize it contains sensitive data, like personal emails or your customers’ data? It happens all the time in most SaaS companies.
        </p>
      </div>
    </section>

    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
        class="d-flex "
      >
        <v-card
          outlined
        >
          <v-card-text class="mb-0 pb-0">
            <img
              src="https://divbyzero.com/wp-content/uploads/2021/11/check-64x64.png.webp"
              alt=""
            >
          </v-card-text>
          <v-card-title>
            Protect private data during calls and product demos
          </v-card-title>
          <v-card-text>
            If you are running a webinar or a live call, it could be too late, and some attendees could have already made a screenshot of some private information you didn’t intend to make public.
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        class="d-flex "
      >
        <v-card outlined>
          <v-card-text class="mb-0 pb-0">
            <img
              src="https://divbyzero.com/wp-content/uploads/2021/11/eye-64x64.png.webp"
              alt=""
            >
          </v-card-text>
          <v-card-title>
            Blur out sensitive data in 1 click
          </v-card-title>
          <v-card-text>
            If you’ve been recording a video, you might want to avoid redoing the whole recording by post-editing it and adding blur on sensitive data.
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        class="d-flex "
      >
        <v-card outlined>
          <v-card-text class="mb-0 pb-0">
            <img
              src="https://divbyzero.com/wp-content/uploads/2021/11/dom-64x64.png.webp"
              alt=""
            >
          </v-card-text>
          <v-card-title>
            Blur HTML elements or areas
          </v-card-title>
          <v-card-text>
            With ZeroBlur, you can select specific elements or areas of your app or website that you want to be blurred. Choose if you’re going to blur them only on one particular page or site-wide, and you’re good to go!
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
      >
        <hr>
      </v-col>
    </v-row>

    <section
      id="how_it_works"
      class="landing-block landing-block-section-heading  mt-5 mb-5"
    >
      <div class="container">
        <h2 class="text-primary text-center">
          How does this Blur Chrome extension work?
        </h2>
        <p class="landing-block__text mb-4 text-center">
          Using ZeroBlur is simple and straightforward
        </p>

        <v-expansion-panels focusable>
          <v-expansion-panel
            v-for="(item,i) in workData"
            :key="i"
          >
            <v-expansion-panel-header>
              {{ item.title }}
            </v-expansion-panel-header>
            <v-expansion-panel-content
              class="px-4 py-4"
              v-html="item.contents"
            >
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </section>

    <section class="landing-block landing-block-section-heading  mt-5 mb-5">
      <div class="container">
        <h2 class="text-primary text-center">
          FAQ
        </h2>

        <v-expansion-panels focusable>
          <v-expansion-panel
            v-for="(item,i) in faqs"
            :key="i"
          >
            <v-expansion-panel-header>
              {{ item.title }}
            </v-expansion-panel-header>
            <v-expansion-panel-content
              class="px-4 py-4"
              v-html="item.contents"
            >
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </section>
  </div>
</template>

<script>
import Data from './Data'

export default {
  data() {
    return {
      workData: Data.work,
      faqs: Data.faqs,
    }
  },
}
</script>
<style scoped lang="scss">
.zeroblur__app {
  .app__title {
    font-size: 3rem;
    font-weight: 700;
    line-height: 1;
    color: #c61044;
  }
  .app__headline {
    color: black;
    font-size: 2rem;
    line-height: 1.2;
    font-weight: 400;
  }
  h2 {
    font-size: calc(1.325rem + 0.9vw);
  }
  .landing-block__text {
    font-size: 1.5rem;
    line-height: 140%;
  }

  .text-primary {
    color: #c61044 !important;
  }

  .text-secondary {
    color: #282f38 !important;
  }
  a,
  .text-decoration-none {
    text-decoration: none !important;
  }
  .landing-block .accordion-body p img {
    border: 1px solid #dadada;
    border-radius: 8px;
    max-width: 100%;
    height: auto;
  }
}
</style>
