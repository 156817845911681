const Data = {
  work: [
    {
      title: 'Instalation',
      contents: ` <style>.landing-block .accordion-body p img {
                    border: 1px solid #dadada;
                    border-radius: 8px;
                    max-width: 100%;
                    height: auto;
                  }</style>
        <div class="accordion-body">
            <p>
              Just head to the <a
                href="https://chrome.google.com/webstore/detail/zeroblur/ckmpibbifmcamfmfelkencbbiilpcfjg"
                target="_blank"
                rel="noopener"
              >Chrome store and install the extension.</a>
            </p>
            <p>

                <img
                  class="responsive__img"
                  src="https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-chrome-extension-install-1024x510.png"
                  alt=""
                >
              <noscript><picture class="alignnone size-large wp-image-1098">
                <source type="image/webp" srcset="https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-chrome-extension-install-1024x510.png.webp 1024w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-chrome-extension-install-300x149.png.webp 300w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-chrome-extension-install-768x382.png.webp 768w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-chrome-extension-install-1536x764.png.webp 1536w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-chrome-extension-install.png.webp 2048w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-chrome-extension-install-350x174.png.webp 350w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-chrome-extension-install-1110x552.png.webp 1110w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-chrome-extension-install-64x32.png.webp 64w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-chrome-extension-install-128x64.png.webp 128w" sizes="(max-width: 1024px) 100vw, 1024px"/>
                <img src="https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-chrome-extension-install-1024x510.png" alt="" width="1024" height="510" srcset="https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-chrome-extension-install-1024x510.png 1024w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-chrome-extension-install-300x149.png 300w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-chrome-extension-install-768x382.png 768w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-chrome-extension-install-1536x764.png 1536w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-chrome-extension-install.png 2048w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-chrome-extension-install-350x174.png 350w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-chrome-extension-install-1110x552.png 1110w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-chrome-extension-install-64x32.png 64w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-chrome-extension-install-128x64.png 128w" sizes="(max-width: 1024px) 100vw, 1024px"/>
                </picture>
              </noscript>
            </p>
            <p>Once installed it you should find the icon next to the URL bar:</p>
            <p>
              <picture class="alignnone size-large wp-image-1100">
                <source
                  type="image/webp"
                  data-lazy-srcset="https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-1024x351.png.webp 1024w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-300x103.png.webp 300w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-768x263.png.webp 768w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-1536x527.png.webp 1536w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access.png.webp 2048w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-350x120.png.webp 350w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-1110x381.png.webp 1110w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-93x32.png.webp 93w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-128x44.png.webp 128w"
                  srcset="https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-1024x351.png.webp 1024w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-300x103.png.webp 300w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-768x263.png.webp 768w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-1536x527.png.webp 1536w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access.png.webp 2048w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-350x120.png.webp 350w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-1110x381.png.webp 1110w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-93x32.png.webp 93w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-128x44.png.webp 128w"
                  data-lazy-sizes="(max-width: 1024px) 100vw, 1024px"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                >
                <img
                  src="https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-1024x351.png"
                  alt=""
                  width="1024"
                  height="351"
                  data-lazy-srcset="https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-1024x351.png 1024w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-300x103.png 300w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-768x263.png 768w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-1536x527.png 1536w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access.png 2048w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-350x120.png 350w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-1110x381.png 1110w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-93x32.png 93w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-128x44.png 128w"
                  data-lazy-sizes="(max-width: 1024px) 100vw, 1024px"
                  data-lazy-src="https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-1024x351.png"
                  data-ll-status="loaded"
                  class="entered lazyloaded"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                  srcset="https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-1024x351.png 1024w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-300x103.png 300w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-768x263.png 768w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-1536x527.png 1536w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access.png 2048w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-350x120.png 350w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-1110x381.png 1110w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-93x32.png 93w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-128x44.png 128w"
                >
              </picture>
              <noscript><picture class="alignnone size-large wp-image-1100">
                <source type="image/webp" srcset="https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-1024x351.png.webp 1024w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-300x103.png.webp 300w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-768x263.png.webp 768w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-1536x527.png.webp 1536w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access.png.webp 2048w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-350x120.png.webp 350w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-1110x381.png.webp 1110w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-93x32.png.webp 93w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-128x44.png.webp 128w" sizes="(max-width: 1024px) 100vw, 1024px"/>
                <img src="https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-1024x351.png" alt="" width="1024" height="351" srcset="https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-1024x351.png 1024w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-300x103.png 300w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-768x263.png 768w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-1536x527.png 1536w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access.png 2048w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-350x120.png 350w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-1110x381.png 1110w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-93x32.png 93w, https://divbyzero.com/wp-content/uploads/2021/10/zeroblur-quick-access-128x44.png 128w" sizes="(max-width: 1024px) 100vw, 1024px"/>
                </picture>
              </noscript>
            </p>
            <p>If it’s not there, likely you have too many extensions installed for Chrome to display all of them. Don’t worry, click on the puzzle icon to show all your extensions and then pin ZeroBlur to make it always visible:</p>
            <p>
              <picture class="alignnone wp-image-1099">
                <source
                  type="image/webp"
                  data-lazy-srcset="https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-175x300.png.webp 175w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-597x1024.png.webp 597w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-204x350.png.webp 204w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-338x580.png.webp 338w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-19x32.png.webp 19w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-75x128.png.webp 75w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension.png.webp 648w"
                  srcset="https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-175x300.png.webp 175w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-597x1024.png.webp 597w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-204x350.png.webp 204w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-338x580.png.webp 338w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-19x32.png.webp 19w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-75x128.png.webp 75w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension.png.webp 648w"
                  data-lazy-sizes="(max-width: 400px) 100vw, 400px"
                  sizes="(max-width: 400px) 100vw, 400px"
                >
                <img
                  src="https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-175x300.png"
                  alt=""
                  width="400"
                  height="686"
                  data-lazy-srcset="https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-175x300.png 175w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-597x1024.png 597w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-204x350.png 204w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-338x580.png 338w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-19x32.png 19w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-75x128.png 75w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension.png 648w"
                  data-lazy-sizes="(max-width: 400px) 100vw, 400px"
                  data-lazy-src="https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-175x300.png"
                  data-ll-status="loaded"
                  class="entered lazyloaded"
                  sizes="(max-width: 400px) 100vw, 400px"
                  srcset="https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-175x300.png 175w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-597x1024.png 597w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-204x350.png 204w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-338x580.png 338w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-19x32.png 19w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-75x128.png 75w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension.png 648w"
                >
              </picture>
              <noscript><picture class="alignnone wp-image-1099">
                <source type="image/webp" srcset="https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-175x300.png.webp 175w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-597x1024.png.webp 597w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-204x350.png.webp 204w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-338x580.png.webp 338w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-19x32.png.webp 19w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-75x128.png.webp 75w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension.png.webp 648w" sizes="(max-width: 400px) 100vw, 400px"/>
                <img src="https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-175x300.png" alt="" width="400" height="686" srcset="https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-175x300.png 175w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-597x1024.png 597w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-204x350.png 204w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-338x580.png 338w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-19x32.png 19w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension-75x128.png 75w, https://divbyzero.com/wp-content/uploads/2021/10/pin-chrome-blur-extension.png 648w" sizes="(max-width: 400px) 100vw, 400px"/>
                </picture>
              </noscript>
            </p>
            <p>Now you are ready to blur any sensitive data from your video calls, webinar, and video recordings.</p>
            <p>Just head to any page or web app. If you had it already open and you just installed the extension, please reload the page before taking any action.</p>
            <p>Next enable the extension on the page you want to blur data on and click edit:</p>
            <p>
              <picture class="alignnone wp-image-1101">
                <source
                  type="image/webp"
                  data-lazy-srcset="https://divbyzero.com/wp-content/uploads/2021/10/enable-blur-extension-300x229.png.webp 300w, https://divbyzero.com/wp-content/uploads/2021/10/enable-blur-extension-350x267.png.webp 350w, https://divbyzero.com/wp-content/uploads/2021/10/enable-blur-extension-42x32.png.webp 42w, https://divbyzero.com/wp-content/uploads/2021/10/enable-blur-extension-128x98.png.webp 128w, https://divbyzero.com/wp-content/uploads/2021/10/enable-blur-extension.png.webp 524w"
                  srcset="https://divbyzero.com/wp-content/uploads/2021/10/enable-blur-extension-300x229.png.webp 300w, https://divbyzero.com/wp-content/uploads/2021/10/enable-blur-extension-350x267.png.webp 350w, https://divbyzero.com/wp-content/uploads/2021/10/enable-blur-extension-42x32.png.webp 42w, https://divbyzero.com/wp-content/uploads/2021/10/enable-blur-extension-128x98.png.webp 128w, https://divbyzero.com/wp-content/uploads/2021/10/enable-blur-extension.png.webp 524w"
                  data-lazy-sizes="(max-width: 400px) 100vw, 400px"
                  sizes="(max-width: 400px) 100vw, 400px"
                >
                <img
                  src="https://divbyzero.com/wp-content/uploads/2021/10/enable-blur-extension-300x229.png"
                  alt=""
                  width="400"
                  height="305"
                  data-lazy-srcset="https://divbyzero.com/wp-content/uploads/2021/10/enable-blur-extension-300x229.png 300w, https://divbyzero.com/wp-content/uploads/2021/10/enable-blur-extension-350x267.png 350w, https://divbyzero.com/wp-content/uploads/2021/10/enable-blur-extension-42x32.png 42w, https://divbyzero.com/wp-content/uploads/2021/10/enable-blur-extension-128x98.png 128w, https://divbyzero.com/wp-content/uploads/2021/10/enable-blur-extension.png 524w"
                  data-lazy-sizes="(max-width: 400px) 100vw, 400px"
                  data-lazy-src="https://divbyzero.com/wp-content/uploads/2021/10/enable-blur-extension-300x229.png"
                  data-ll-status="loaded"
                  class="entered lazyloaded"
                  sizes="(max-width: 400px) 100vw, 400px"
                  srcset="https://divbyzero.com/wp-content/uploads/2021/10/enable-blur-extension-300x229.png 300w, https://divbyzero.com/wp-content/uploads/2021/10/enable-blur-extension-350x267.png 350w, https://divbyzero.com/wp-content/uploads/2021/10/enable-blur-extension-42x32.png 42w, https://divbyzero.com/wp-content/uploads/2021/10/enable-blur-extension-128x98.png 128w, https://divbyzero.com/wp-content/uploads/2021/10/enable-blur-extension.png 524w"
                >
              </picture>
              <noscript><picture class="alignnone wp-image-1101">
                <source type="image/webp" srcset="https://divbyzero.com/wp-content/uploads/2021/10/enable-blur-extension-300x229.png.webp 300w, https://divbyzero.com/wp-content/uploads/2021/10/enable-blur-extension-350x267.png.webp 350w, https://divbyzero.com/wp-content/uploads/2021/10/enable-blur-extension-42x32.png.webp 42w, https://divbyzero.com/wp-content/uploads/2021/10/enable-blur-extension-128x98.png.webp 128w, https://divbyzero.com/wp-content/uploads/2021/10/enable-blur-extension.png.webp 524w" sizes="(max-width: 400px) 100vw, 400px"/>
                <img src="https://divbyzero.com/wp-content/uploads/2021/10/enable-blur-extension-300x229.png" alt="" width="400" height="305" srcset="https://divbyzero.com/wp-content/uploads/2021/10/enable-blur-extension-300x229.png 300w, https://divbyzero.com/wp-content/uploads/2021/10/enable-blur-extension-350x267.png 350w, https://divbyzero.com/wp-content/uploads/2021/10/enable-blur-extension-42x32.png 42w, https://divbyzero.com/wp-content/uploads/2021/10/enable-blur-extension-128x98.png 128w, https://divbyzero.com/wp-content/uploads/2021/10/enable-blur-extension.png 524w" sizes="(max-width: 400px) 100vw, 400px"/>
                </picture>
              </noscript>
            </p>
            <p>Once you click the edit button you’ll enter edit mode to select which area of the page you want to blur to keep your data safe.</p>
            <p>The control bar will be positioned on the right side of the page:</p>
            <p>
              <picture class="alignnone size-large wp-image-1102">
                <source
                  type="image/webp"
                  data-lazy-srcset="https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-1024x821.png.webp 1024w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-300x241.png.webp 300w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-768x616.png.webp 768w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-1536x1232.png.webp 1536w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings.png.webp 2048w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-350x281.png.webp 350w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-723x580.png.webp 723w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-40x32.png.webp 40w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-128x103.png.webp 128w"
                  srcset="https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-1024x821.png.webp 1024w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-300x241.png.webp 300w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-768x616.png.webp 768w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-1536x1232.png.webp 1536w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings.png.webp 2048w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-350x281.png.webp 350w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-723x580.png.webp 723w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-40x32.png.webp 40w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-128x103.png.webp 128w"
                  data-lazy-sizes="(max-width: 1024px) 100vw, 1024px"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                >
                <img
                  src="https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-1024x821.png"
                  alt=""
                  width="1024"
                  height="821"
                  data-lazy-srcset="https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-1024x821.png 1024w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-300x241.png 300w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-768x616.png 768w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-1536x1232.png 1536w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings.png 2048w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-350x281.png 350w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-723x580.png 723w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-40x32.png 40w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-128x103.png 128w"
                  data-lazy-sizes="(max-width: 1024px) 100vw, 1024px"
                  data-lazy-src="https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-1024x821.png"
                  data-ll-status="loaded"
                  class="entered lazyloaded"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                  srcset="https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-1024x821.png 1024w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-300x241.png 300w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-768x616.png 768w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-1536x1232.png 1536w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings.png 2048w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-350x281.png 350w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-723x580.png 723w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-40x32.png 40w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-128x103.png 128w"
                >
              </picture>
              <noscript><picture class="alignnone size-large wp-image-1102">
                <source type="image/webp" srcset="https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-1024x821.png.webp 1024w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-300x241.png.webp 300w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-768x616.png.webp 768w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-1536x1232.png.webp 1536w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings.png.webp 2048w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-350x281.png.webp 350w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-723x580.png.webp 723w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-40x32.png.webp 40w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-128x103.png.webp 128w" sizes="(max-width: 1024px) 100vw, 1024px"/>
                <img src="https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-1024x821.png" alt="" width="1024" height="821" srcset="https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-1024x821.png 1024w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-300x241.png 300w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-768x616.png 768w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-1536x1232.png 1536w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings.png 2048w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-350x281.png 350w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-723x580.png 723w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-40x32.png 40w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-edit-settings-128x103.png 128w" sizes="(max-width: 1024px) 100vw, 1024px"/>
                </picture>
              </noscript>
            </p>
            <p>Once in edit mode, you have two ways to blur information.</p>
            <p>You can pick a specific HTML element of the page that you want to blur, or you can drag and drop an entire area to hide.</p>
          </div>
                            `,
    }, {
      title: 'Blur a specific HTML element',
      contents: `
      <div class="accordion-body">
        <p>Blurring a specific element of the page is really simple. From the control bar click the first icon and then select the DOM element you want to blur.</p>
        <p>
          <img
            class="alignnone size-full wp-image-1103 entered lazyloaded"
            src="https://divbyzero.com/wp-content/uploads/2021/10/blur-html-element.gif"
            alt=""
            width="800"
            height="430"
            data-lazy-src="https://divbyzero.com/wp-content/uploads/2021/10/blur-html-element.gif"
            data-ll-status="loaded"
          ><noscript><img class="alignnone size-full wp-image-1103" src="https://divbyzero.com/wp-content/uploads/2021/10/blur-html-element.gif" alt="" width="800" height="430" /></noscript>
        </p>
      </div>
    `,
    }, {
      title: 'Blur an area of the page',
      contents: `
      <div class="accordion-body">
        <p>With my free blur chrome extension you can also drag and drop a whole area that you want to hide. This is useful when you have large columns with sensitive data. Here’s how to do it:</p>
        <p>
          <img
            class="alignnone size-full wp-image-1104 entered lazyloaded"
            alt=""
            width="800"
            height="526"
            data-lazy-src="https://divbyzero.com/wp-content/uploads/2021/10/blur-page-area.gif"
            src="https://divbyzero.com/wp-content/uploads/2021/10/blur-page-area.gif"
            data-ll-status="loaded"
          ><noscript><img class="alignnone size-full wp-image-1104" src="https://divbyzero.com/wp-content/uploads/2021/10/blur-page-area.gif" alt="" width="800" height="526" /></noscript>
        </p>
        <p>Moving forward that specific are of the screen on that page will always be blurred if the extension is active.</p>
      </div>
    `,
    }, {
      title: 'Advanced blur settings',
      contents: `
     <div class="accordion-body">
      <p>Once you’ve blurred a few elements and areas to blur, you’ll notice each one has two icons, a globe and a trash can.</p>
      <p>
        <picture class="alignnone size-large wp-image-1105">
          <source
            type="image/webp"
            data-lazy-srcset="https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-1024x669.png.webp 1024w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-300x196.png.webp 300w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-768x502.png.webp 768w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-1536x1003.png.webp 1536w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-350x229.png.webp 350w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-888x580.png.webp 888w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-49x32.png.webp 49w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-128x84.png.webp 128w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings.png.webp 1920w"
            data-lazy-sizes="(max-width: 1024px) 100vw, 1024px"
            srcset="https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-1024x669.png.webp 1024w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-300x196.png.webp 300w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-768x502.png.webp 768w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-1536x1003.png.webp 1536w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-350x229.png.webp 350w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-888x580.png.webp 888w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-49x32.png.webp 49w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-128x84.png.webp 128w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings.png.webp 1920w"
            sizes="(max-width: 1024px) 100vw, 1024px"
          >
          <img
            alt=""
            width="1024"
            height="669"
            data-lazy-srcset="https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-1024x669.png 1024w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-300x196.png 300w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-768x502.png 768w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-1536x1003.png 1536w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-350x229.png 350w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-888x580.png 888w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-49x32.png 49w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-128x84.png 128w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings.png 1920w"
            data-lazy-sizes="(max-width: 1024px) 100vw, 1024px"
            data-lazy-src="https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-1024x669.png"
            class="entered lazyloaded"
            src="https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-1024x669.png"
            data-ll-status="loaded"
            sizes="(max-width: 1024px) 100vw, 1024px"
            srcset="https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-1024x669.png 1024w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-300x196.png 300w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-768x502.png 768w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-1536x1003.png 1536w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-350x229.png 350w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-888x580.png 888w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-49x32.png 49w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-128x84.png 128w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings.png 1920w"
          >
        </picture>
        <noscript><picture class="alignnone size-large wp-image-1105">
          <source type="image/webp" srcset="https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-1024x669.png.webp 1024w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-300x196.png.webp 300w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-768x502.png.webp 768w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-1536x1003.png.webp 1536w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-350x229.png.webp 350w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-888x580.png.webp 888w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-49x32.png.webp 49w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-128x84.png.webp 128w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings.png.webp 1920w" sizes="(max-width: 1024px) 100vw, 1024px"/>
          <img src="https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-1024x669.png" alt="" width="1024" height="669" srcset="https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-1024x669.png 1024w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-300x196.png 300w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-768x502.png 768w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-1536x1003.png 1536w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-350x229.png 350w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-888x580.png 888w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-49x32.png 49w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings-128x84.png 128w, https://divbyzero.com/wp-content/uploads/2021/10/blur-extension-settings.png 1920w" sizes="(max-width: 1024px) 100vw, 1024px"/>
          </picture>
        </noscript>
      </p>
      <p>The globe icon is a toggle to select if you want to blur that specific element sitewide or just on the current page.</p>
      <p>This is super useful when you want to blur some specific interface element like your user-id that is displayed on every page of the website or app.</p>
      <p>The trashcan icon simply remove that specific blur.</p>
      <p>Finally the trashcan inside the control bar will remove all the blur areas together.</p>
    </div>
    `,
    },
  ],
  faqs: [
    {
      title: 'Is the extension 100% Free?', contents: `
        <div class="accordion-body">
              <p>Yes, it’s 100% free and it will always be free.</p>
              <p>No Signup is required, no email, no money… nothing!</p>
              <p>In the near future I might add a signup option to allow cross device syncing of the blur areas. That’s it!</p>
            </div>
        `,
    }, {
      title: 'Will it slow down my browser?',
      contents: `
      <div class="accordion-body">
          <p>Yes and no.</p>
          <p>Every extension you add to your browser will somehow make it a bit slower.</p>
          <p>ZeroBlur is a lightweight extension and it won’t add any meaningful overhead to your browsing, don’t worry about that.</p>
          <p>To maximise performances tho’ I suggest that you disable the blurring when not needed. In this way when you don’t need the extension it will be completely off.</p>
          <p>
            <picture class="alignnone size-full wp-image-1285">
              <source
                type="image/webp"
                data-lazy-srcset="https://divbyzero.com/wp-content/uploads/2021/11/zeroblur-disabled.png.webp 523w, https://divbyzero.com/wp-content/uploads/2021/11/zeroblur-disabled-300x190.png.webp 300w, https://divbyzero.com/wp-content/uploads/2021/11/zeroblur-disabled-355x225.png.webp 355w, https://divbyzero.com/wp-content/uploads/2021/11/zeroblur-disabled-50x32.png.webp 50w, https://divbyzero.com/wp-content/uploads/2021/11/zeroblur-disabled-128x81.png.webp 128w"
                srcset="https://divbyzero.com/wp-content/uploads/2021/11/zeroblur-disabled.png.webp 523w, https://divbyzero.com/wp-content/uploads/2021/11/zeroblur-disabled-300x190.png.webp 300w, https://divbyzero.com/wp-content/uploads/2021/11/zeroblur-disabled-355x225.png.webp 355w, https://divbyzero.com/wp-content/uploads/2021/11/zeroblur-disabled-50x32.png.webp 50w, https://divbyzero.com/wp-content/uploads/2021/11/zeroblur-disabled-128x81.png.webp 128w"
                data-lazy-sizes="(max-width: 523px) 100vw, 523px"
                sizes="(max-width: 523px) 100vw, 523px"
              >
              <img
                src="https://divbyzero.com/wp-content/uploads/2021/11/zeroblur-disabled.png"
                alt="Disable zeroblur"
                width="523"
                height="332"
                data-lazy-srcset="https://divbyzero.com/wp-content/uploads/2021/11/zeroblur-disabled.png 523w, https://divbyzero.com/wp-content/uploads/2021/11/zeroblur-disabled-300x190.png 300w, https://divbyzero.com/wp-content/uploads/2021/11/zeroblur-disabled-355x225.png 355w, https://divbyzero.com/wp-content/uploads/2021/11/zeroblur-disabled-50x32.png 50w, https://divbyzero.com/wp-content/uploads/2021/11/zeroblur-disabled-128x81.png 128w"
                data-lazy-sizes="(max-width: 523px) 100vw, 523px"
                data-lazy-src="https://divbyzero.com/wp-content/uploads/2021/11/zeroblur-disabled.png"
                data-ll-status="loaded"
                class="entered lazyloaded"
                sizes="(max-width: 523px) 100vw, 523px"
                srcset="https://divbyzero.com/wp-content/uploads/2021/11/zeroblur-disabled.png 523w, https://divbyzero.com/wp-content/uploads/2021/11/zeroblur-disabled-300x190.png 300w, https://divbyzero.com/wp-content/uploads/2021/11/zeroblur-disabled-355x225.png 355w, https://divbyzero.com/wp-content/uploads/2021/11/zeroblur-disabled-50x32.png 50w, https://divbyzero.com/wp-content/uploads/2021/11/zeroblur-disabled-128x81.png 128w"
              >
            </picture>
            <noscript><picture class="alignnone size-full wp-image-1285">
              <source type="image/webp" srcset="https://divbyzero.com/wp-content/uploads/2021/11/zeroblur-disabled.png.webp 523w, https://divbyzero.com/wp-content/uploads/2021/11/zeroblur-disabled-300x190.png.webp 300w, https://divbyzero.com/wp-content/uploads/2021/11/zeroblur-disabled-355x225.png.webp 355w, https://divbyzero.com/wp-content/uploads/2021/11/zeroblur-disabled-50x32.png.webp 50w, https://divbyzero.com/wp-content/uploads/2021/11/zeroblur-disabled-128x81.png.webp 128w" sizes="(max-width: 523px) 100vw, 523px"/>
              <img src="https://divbyzero.com/wp-content/uploads/2021/11/zeroblur-disabled.png" alt="Disable zeroblur" width="523" height="332" srcset="https://divbyzero.com/wp-content/uploads/2021/11/zeroblur-disabled.png 523w, https://divbyzero.com/wp-content/uploads/2021/11/zeroblur-disabled-300x190.png 300w, https://divbyzero.com/wp-content/uploads/2021/11/zeroblur-disabled-355x225.png 355w, https://divbyzero.com/wp-content/uploads/2021/11/zeroblur-disabled-50x32.png 50w, https://divbyzero.com/wp-content/uploads/2021/11/zeroblur-disabled-128x81.png 128w" sizes="(max-width: 523px) 100vw, 523px"/>
              </picture>
            </noscript>
          </p>
        </div>
            `,
    },
  ],
}
export default Data
